import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//每个菜单对应的页面配置
const routes = [
  {
    path: '/login',
    name: '登录',
    meta: {
      title: '睿互动辅助教学平台'
    },
    component:  () => import('../views/Login/Login.vue')
  },
  //下面路径映射分了children和菜单的分级无关，只是说明下面的所有路径映射都是再/首页中的，所以写道了childern里
  {
    path: '/',
    name: '首页',
    meta: {
      title: '睿互动辅助教学平台'
    },
    component: () => import('../views/Home.vue'),
    children: [
      //对应刚进入首页或点击管理员头像或点击左上角后切换回的当前管理员信息页面
      {
        path: '/bindex/admininfo',
        name: '管理员信息',
        component: () => import('../views/admininfo/managerInfo.vue'),
      },
      //系统配置中的角色管理
      {
        path: '/role/getlist',
        name: '角色管理',
        component: () => import('../views/role/getlist.vue'),
      },
      //系统配置中的菜单管理
      {
        path: '/menu/getlist',
        name: '菜单管理',
        component: () => import('../views/menu/getlist.vue'),
      },
      //系统配置中的管理员列表管理
      {
        path: '/admini/getlist',
        name: '管理员列表',
        component: () => import('../views/admini/getlist.vue'),
      },
      //查看任务列表页面
      {
        path: '/task/getlist',
        name: '任务列表',
        component: () => import('../views/task/getlist.vue'),
      },
      //查看考生列表页面
      {
        path: '/user/getlist',
        name: '学生列表',
        component: () => import('../views/user/getlist.vue'),
      },
      //查看部门列表页面
      {
        path: '/co/getlist',
        name: '部门列表',
        component: () => import('../views/co/getlist.vue'),
      },
      //已批阅评价
      {
        path: '/approved/getlist',
        name: '已批阅列表',
        component: () => import('../views/approved/getlist.vue'),
      },
      //待批阅评价
      {
        path: '/review/getlist',
        name: '待批阅列表',
        component: () => import('../views/review/getlist.vue'),
      },                
      //统计页面列表
      {
        path: '/statistics/getlist',
        name: '数据统计',
        component: () => import('../views/statistics/getlist.vue'),
      },
      //分单个任务统计
      {
        path: '/statask/getlist',
        name: '任务完成统计',
        component: () => import('../views/statask/getlist.vue'),
      },
      //发布平时成绩页面
      {
        path: '/scorefile/getlist',
        name: '平时成绩发布',
        component: () => import('../views/scorefile/getlist.vue'),
      },
      //上传任务
      {
        path: '/taskfile/getlist',
        name: '上传任务文件',
        component: () => import('../views/taskfile/getlist.vue'),
      },
       //知识图谱列表
       {
        path: '/knowledge/getlist',
        name: '知识图谱管理',
        meta:{keepAlive:true},
        component: () => import('../views/knowledge/getlist.vue'),
      },
      {
        path: '/knowledge/question',
        name: '节点题目管理',
        component: () => import('../views/knowledge/question.vue'),
      },
      {
        path: '/knowledge/updatefile',
        name: '节点资源上传',
        component: () => import('../views/knowledge/updatefile.vue'),
      },
      //测试用
      {
        path: '/bindex/admin',
        name: '测试页面',
        component: () => import('../views/test/about.vue'),
      }
    ]
  },

]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
