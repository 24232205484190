import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
//相当于全局变量，和缓存不一样
export default new Vuex.Store({
    state: {
        updateMenu: false, // 定义一个更新Home 左边List 标志，对数据的全局存储全局变量
    },
    getters: { //可以理解为computed ，对数据进行计算,返回全局变量状态
        // 得到标志
        getMenuFlag: (state) => {
            return state.updateMenu
        }
    },
    mutations: {//对数据的同步更改
        // 设置标志，下面的state参数是7行定义的
        setMenuFlag: (state, param) => {
            state.updateMenu = param
        }
    },
    actions: {},
    modules: {}
})
